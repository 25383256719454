@import 'colors';

.toggle-button {
  background: $orange;
  border: none;
  color: $black;
  border-radius: 1.5rem;
  font-weight: bold;
  height: 160px;
  text-transform: uppercase;
  transition-duration: 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    background: $blue;
    color: $white;
    cursor: pointer;
  }

  &.active {
    background: $green;
    color: $white;

    &:hover {
      background: $brown;
      color: $white;
      cursor: pointer;
    }
  }
}

.cta {
  background: $blue;
  border: none;
  color: $white;
  border-radius: 1.5rem;
  font-weight: bold;
  margin: 25px auto;
  padding: 8px 16px;
  text-transform: uppercase;
  transition-duration: 250ms;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $blue;
    color: $white;
    cursor: pointer;
  }
}