@import 'buttons';
@import 'colors';
@import 'fonts';
@import 'text';

body {
  background: $black;
  font-family: "Avenir Next";
}

.landscape {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
}